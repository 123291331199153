import { configureStore } from "@reduxjs/toolkit";
import concertsSliceReducer from "./concertsSlice";
import menuSliceReducer from "./menuSlice";
import productsSliceReducer from "./productSlice";

const store = configureStore({
  reducer: {
    concerts: concertsSliceReducer,
    menu: menuSliceReducer,
    products: productsSliceReducer
  }
})

export default store;
