import { useSelector } from "react-redux";
import styled from "styled-components";
import Concert from "../Concert/Concert";

const ConcertsContainer = styled.div`
  padding-top: 30vh;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
    padding-top: 15vh;
    justify-content: flex-start;
    align-items: center;
  }
`;

const ConcertsDisplay = () => {
  const {concerts} = useSelector((state) => state.concerts);
  const orderedConcerts = [...concerts].sort((a,b) => {return new Date(a.date) - new Date(b.date)})

  return (
    <ConcertsContainer>
      {orderedConcerts.map((concert) => (
        <Concert concert={concert} key={concert.id} />
      ))}
      </ConcertsContainer>

  );
}

export default ConcertsDisplay;