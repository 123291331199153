import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    open: false
  },
  reducers: {
    openMenu: (state) => {
      state.open = true;
    },
    closeMenu: (state) => {
      state.open = false
    }
  }
});

export const { 
  openMenu: openMenuActionCreator, 
  closeMenu: closeMenuActionCreator 
} = menuSlice.actions;

export default menuSlice.reducer;