import { useState } from "react";
import styled from "styled-components";

const CookieAdviseAndContactContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 20px;
  bottom: 70px;
  right: 30px;
  z-index: 1;
  background-color: #fff;
  width: 300px;
  padding: 10px 0;
  font-family: Helvetica, Arial, sans-serif;

  & p {
    margin: 0;
    width: 100%;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: -1px;
    display: flex;
    justify-content: center;
    text-align: justify;
  }

  @media (max-width: 768px) {
    top: 50px;
    right: 10px;
    bottom: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: -1px;
  text-decoration: underline;

  & button {
  background: none;
  border: none;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: -1px;
  text-decoration: underline;
  cursor: pointer;
  }
`;


const CookieAdvise = () => {
  const [showAdvise, setShowAdvise] = useState(true);

  const handleAccept = () => {
    setShowAdvise(false);
  }
  return (
    <CookieAdviseAndContactContainer className={showAdvise ? "" : "hidden"}>
        <p>AL USAR ESTA PÁGINA, ACEPTAS AL POSIBLE USO DE COOKIES DE TERCEROS PARA MEJORAR EL RENDIMIENTO Y TU EXPERIENCIA DE USUARIO .</p>
        <Buttons>
          <button onClick={handleAccept}>ACCEPTAR</button>
        </Buttons>
    </CookieAdviseAndContactContainer>
  );
}

export default CookieAdvise;