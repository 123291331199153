import styled from "styled-components";

const ConcertContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: -1px;
  writing-mode: horizontal-tb;
  text-orientation: mixed;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 800;
  transform: scaleX(0.9);

  @media (max-width: 768px) {
    width: 400px;
    gap: 3px;
    :last-child{
      padding-bottom: 200px;
    }
  }

`;

const ConcertLine = styled.p`
  width: max-content !important;
  margin-top: -20px !important;
  font-size: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    &.concertLine{
      font-size: 42px;
      transform: scale(0.6,0.7) !important;
      margin-top: -29px !important;
    }
  }
`;

const ConcertTickets = styled.a`
  width: 100% !important;
  margin-top: 10px;
  font-size: 22px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 18px;
  }
`;

const Concert = ({ concert }) => {
  const localDateFormat = new Date(concert?.date).toLocaleDateString();
  return (
    <ConcertContainer>
      <ConcertLine className="concertLine">{} { localDateFormat } | {concert.city}</ConcertLine>
      <ConcertLine className="concertLine">{concert.venue}</ConcertLine>
      <ConcertTickets href={concert.tickets} target="_blank">BUY TICKETS</ConcertTickets>
    </ConcertContainer>
  );
}

export default Concert;