import styled from "styled-components";

const ContactMenuContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  background-color: #fff;
  gap: 20px;
  padding: 10px;
  bottom: 30px;
  right: 30px;
  z-index: 1;

  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
    bottom: unset;
  }
`;

const ContactMenuItem = styled.a`
  font-weight: 800;
  font-size: 12px;
  letter-spacing: -1px;
  cursor: pointer;
`;

const ContactMenu = () => {
  return (
    <ContactMenuContainer>
      <ContactMenuItem href="https://www.instagram.com/alizzz/" target="_blank">INSTAGRAM</ContactMenuItem>
      <ContactMenuItem href="https://open.spotify.com/artist/23herDudxPBB3S81GB5uG3?si=RtfVCy1yRESE113bMGQkbg" target="_blank">SPOTIFY</ContactMenuItem>
      <ContactMenuItem href="https://www.youtube.com/@alizzzmusic" target="_blank">YOUTUBE</ContactMenuItem>
    </ContactMenuContainer>
  );
}

export default ContactMenu;