import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { openMenuActionCreator } from "../../redux/menuSlice";
import ConcertsDisplay from "../ConcertsDisplay/ConcertsDisplay";
import ContactForm from "../ContactForm/ContactForm";
import Merchandising from "../Merchandising/Merchandising";

const MenuContainer = styled.div` 
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    position: unset;
  }
`;

const MenuItem = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  box-shadow: 4px 0px 3px 0px rgba(0,0,0,0.2);
  transition: 0.5s;
  text-align: right;
  vertical-align: text-top;
  font-size: 70px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  overflow-x: auto;
  font-family: Helvetica, sans-serif;
  overflow-y: hidden;

  -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

  width: 75vw !important;
  height: 100%;

  @media (max-width: 768px) {
    width: 100vw !important;
    writing-mode: horizontal-tb;
    text-orientation: mixed;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    box-shadow: 0px -3px 3px 0px rgb(0 0 0 / 20%);
  }

  &.active {
    translate: calc(75vw - 90px);

    @media (max-width: 768px) {
      translate: 0 calc(-80% + 100px);
    }
  }

  p {
    display: flex;
    align-items: center;
    width: 90px; 
    margin: 0;
    padding: 0;
    vertical-align: text-top;
    text-align: right;
    transform: scale(0.8, 0.9);
    letter-spacing: -1px;
    font-weight: 600;
    cursor: pointer;
    z-index: 2;

    @media (max-width: 768px) {
      width: auto;
      height: 50px;
      font-size: 56px;
      text-align: center;
      transform: scale(0.6, 0.8) !important;
      letter-spacing: -3px !important;
    }
  }

  &.first {
    left: calc(-75vw + 270px);
    z-index: 2;

    @media (max-width: 768px) {
      left: 0;
      bottom: calc(-100% + 150px);
    }
  } 

  &.second {
    left: calc(-75vw + 180px);
    z-index: 3;

    @media (max-width: 768px) {
      left: 0;
      bottom: calc(-100% + 100px);
    }
  }

  &.third {
    left: calc(-75vw + 90px);
    z-index: 4;

    @media (max-width: 768px) {
      left: 0;
      bottom: calc(-100% + 50px);
    }
  }

  .content {
    position: absolute;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &.shows {
      display: flex;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: calc(100% - 50px);
    }
  }
`;

const Shows = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% - 90px);

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 50px);
  }
`;

const Title = styled.p`
  width: 90px;
  transform: scale(0.9, 0.6) !important;
  letter-spacing: -5px !important;
  font-size: 110px;
`

const Menu = () => {
  const isMenuOpen = useSelector(state => state.menu.open)
  const dispatch = useDispatch()

  const [activeShows, setActiveShows] = useState(false);
  const [activeMerch, setActiveMerch] = useState(false);
  const [activeContact, setActiveContact] = useState(false);

  const desactivateMenu = () => {
    setActiveContact(false);
    setActiveMerch(false);
    setActiveShows(false)
  };

  useEffect(() => {
    !isMenuOpen && desactivateMenu()
  }, [isMenuOpen])

  useEffect(() => {
    const menuItems = document.querySelectorAll(".MENU .first, .MENU .second, .MENU .third");
    menuItems.forEach((item) => {
      const display = item.querySelector(".merchandising")
      const content = item.querySelector(".content")
      if (content && display) content.style.width = display.offsetWidth + "px";
    });
  }, []);
  
  const toggleMenu = (tab) => {
    dispatch(openMenuActionCreator())
    if (tab === "shows") {
      if(activeContact || activeMerch) {
        setActiveContact(false);
        setActiveMerch(false);
        setActiveShows(true);
        return;
      }
      if(activeShows) {
        setActiveShows(false);
        return;
      }
      setActiveShows(true);
    }

    if (tab === "merchandising") {
      if(activeContact) {
        setActiveContact(false);
        setActiveMerch(true);
        return;
      }
      if(activeMerch) {
        setActiveMerch(false);
        setActiveShows(false);
        return;
      }
      setActiveContact(false)
      setActiveMerch(true);
      setActiveShows(true);
    }
    if (tab === "contacto") {
      if(activeContact) {
        setActiveContact(false);
        setActiveMerch(false);
        setActiveShows(false);
        return;
      }
      setActiveContact(true);
      setActiveMerch(true);
      setActiveShows(true);
    }
  };

  return (
    <MenuContainer className="MENU">
      <MenuItem className={activeContact ? "active third menuItem" : "third menuItem"} >
        <Title onClick={() => toggleMenu("contacto")}>CONTACTO</Title>
        <Shows>
          <div className="content">
            <ContactForm/>
          </div>
        </Shows>
      </MenuItem>
      <MenuItem className={activeMerch ? "active second menuItem" : "second menuItem"} >
        <Title onClick={() => toggleMenu("merchandising")}>MERCHANDISING</Title>
        <Shows className="merchandising">
          <div className="content">
            <Merchandising/>
          </div>
        </Shows>
      </MenuItem>
      <MenuItem className={activeShows ? "active first menuItem" : "first menuItem"} >
        <Title onClick={() => toggleMenu("shows")}>SHOWS</Title>
        <Shows>
          <div className="content shows">
            <ConcertsDisplay/> 
          </div>
          </Shows>
      </MenuItem>
    </MenuContainer>
  );
}

export default Menu;