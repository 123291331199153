import { createSlice } from "@reduxjs/toolkit";

const concertsSlice = createSlice({
  name: "concert",
  initialState: {
    concerts: []
  },
  reducers: {
    loadConcerts: (state, action) => {
      state.concerts = action.payload;
    }
  }
});

export const { loadConcerts: loadConcertsActionCreator } = concertsSlice.actions;

export default concertsSlice.reducer;