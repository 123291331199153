import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ContactMenu from "./components/ContactMenu/ContactMenu";
import CookieAdvise from "./components/CookieAdvise/CookieAdvise.js";
import Menu from "./components/Menu/Menu";
import { loadConcertsActionCreator } from "./redux/concertsSlice";
import { closeMenuActionCreator } from "./redux/menuSlice";
import { loadProductsActionCreator } from "./redux/productSlice";

const AppContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    box-sizing: border-box;
    margin: 0;
    height: 100vh;
    overflow: hidden;
    flex: 1;
    height: ${(props) => props.device === "iOS" ? "-moz-available" : "100vh"};
    height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "100vh"};
    height: ${(props) => props.device === "iOS" ? "fill-available" : "100vh"};
    min-height: ${(props) => props.device === "iOS" ? "-webkit-fill-available" : "none"};
    padding-bottom: env(safe-area-inset-bottom);
    position: relative;

  }
`;

const HomeContainer = styled.div`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    box-sizing: border-box;
    margin: 0;
    flex-direction: column;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    } 
`;

const ClosingMenuLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0.5;
`;

const Filler1 = styled.div`
  height: 200px;
  width: 100vw;
`;

const Filler2 = styled.div`
  height: ${(props) => props.fullHeight}px;
  width: 100vw;
`;

function App() {
  const [frontPageImage, setFrontPageImage] = useState(null);

  const dispatch = useDispatch()
  const ref = useRef(null);
  const isMobile = window.innerWidth <= 768;
  
  const [mobileOS, setMobileOS] = useState(null);
  const [height, setHeight] = useState(null);
  const [textShown, setTextShown] = useState(false);

  useEffect(()=>{
    if (textShown) {
      console.log('CODE DEVELOPED BY ALEJANDRO MACHIN')
    }
      setTextShown(true)
  }, [textShown])

  useEffect(() => {
    const fetchingData = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}concerts`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((concert) => ({...concert.attributes, id: concert.id}));
        dispatch(loadConcertsActionCreator(beautifiedData));
      })

      await axios.get(`${process.env.REACT_APP_API_URL}products`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((concert) => ({...concert.attributes, id: concert.id}));
        dispatch(loadProductsActionCreator(beautifiedData));
      })

      await axios.get(`${process.env.REACT_APP_API_URL}front-page-images`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        const beautifiedData = response.data.data?.map((image) => ({...image.attributes, id: image.id}));
        setFrontPageImage(beautifiedData[0].url);
      })
    }
    fetchingData();
  }, [dispatch])

  useEffect(()=>{
    setMobileOS(getMobileOperatingSystem())
    setHeight(ref.current.offsetHeight);
  }, [height])

  const closeMenus = () => {
    const menus = document.querySelectorAll(".menuItem");
    menus.forEach((menu) => {
      menu.classList.remove("active");
    });
    dispatch(closeMenuActionCreator())
  };

  function getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
          return "Android";
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
      return "unknown";
  }

  return (
    <AppContainer ref={ref} className="APP" device={mobileOS}>
    <HomeContainer background={frontPageImage}>
      {isMobile && <Filler1/> &&
      <Filler2 fullHeight={height - 200}/>
      }
    <Menu/>
    <CookieAdvise></CookieAdvise>
    <ContactMenu/>
    <ClosingMenuLayer onClick={()=> closeMenus()}/>
    </HomeContainer>
    </AppContainer>
  );
}

export default App;
