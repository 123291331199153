import { useEffect, useRef, useState } from "react";
import styled from "styled-components"

const ProductText = styled.div`
  width: 100%;
  //background-color: rgb(255,255,255,0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20%;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 800;
  transform: scaleX(0.9);
  opacity: 0;
  gap: 3px;
  transition: all 0.5s ease-in-out;


  h2, p {
    text-align: center;
    font-size: 23px;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
    margin: 0;
    width: auto !important;

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }

  p {
    width: auto;
    display: flex;
    margin-top: -8px;
  }

  @media (max-width: 768px) {
    .productPrice {
      font-size: 28px;
    }
  }
`;

const BuyProduct = styled.p`
  width: 100% !important;
  margin-top: 10px;
  font-size: 22px !important;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
  font-family: 'Times New Roman', Times, serif;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;

  @media (max-width: 768px) {
    bottom: 0;
  }
`

const ProductImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image1});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;

`;

const ProductContainer = styled.div`
  position: relative;
  font-size: 12px;
  height: 50%;
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  writing-mode: horizontal-tb;
  text-orientation: mixed;

  @media (max-width: 768px) {
    width: 50%;
    height: 40%;
  }

  &:hover {
    ${ProductText}, ${BuyProduct} {
      opacity: 1;
    }
    ${ProductImage} {
      background-image: url(${(props) => props.image2});
    }

    @media (max-width: 768px) {
      ${ProductText}, ${BuyProduct} {
        opacity: 0;
      }
    }
  }
`;  

const Product = ({product}) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const text = useRef(null)
  const buyProduct = useRef(null)
  const isMobile = window.innerWidth < 768

  useEffect(() => {
    if (!isMobile) {
      setIsDisabled(false)
    }
  }, [isMobile])

  const toggleText = () => {

    if (text.current.style.opacity === "1") {
      text.current.style.opacity = "0"
      buyProduct.current.style.opacity = "0"
      setIsDisabled(true)
    } else {
      text.current.style.opacity = "1"
      buyProduct.current.style.opacity = "1"
      setIsDisabled(false)
    }
  }

  const openNewTab = () => {
    window.open(product.url, "_blank")
  }

  return (
    <ProductContainer onClick={isMobile ? toggleText : null} image2={product.image2}>
      <ProductImage image1={product.image1} ></ProductImage>
      <ProductText ref={text} className="text">
        <h2>{product.name}</h2>
        <p className="productPrice">{product.color} {product.price}</p>
      </ProductText>
      <BuyProduct ref={buyProduct} onClick={isDisabled ? null : openNewTab} >COMPRAR</BuyProduct>
    </ProductContainer>
  )
}

export default Product