import emailjs from '@emailjs/browser';
import { useEffect, useRef, useState } from "react";

//import { useDispatch } from "react-redux";
import styled from "styled-components";

const ContactFormContainer = styled.div`
  display: flex;
  height: 100%;
  width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: -1px;
  writing-mode: horizontal-tb;
  text-orientation: mixed;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 800;
  transform: scaleX(0.9);

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-size: 26px;
    transform: scale(0.8, 0.8);
    letter-spacing: -1px;

    @media (max-width: 768px) {
      transform: none;
      font-size: 20px;
      gap: 20px;
    }

    button, input, textarea, textarea::placeholder, input::placeholder{
      background: none;
      border: none;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 800;
      transform: scaleX(0.9);
      font-size: 26px;
  
      &.disabled{
        cursor: auto;
      }
    }

    button {
      cursor: pointer;
      text-decoration: underline;
    }

    input, textarea::placeholder, input::placeholder{
      text-decoration: none;
      color: #000;
      font-size: 30px;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    input, textarea {
      width: 200px;
      border: none;
      text-align: center;
      resize: none;

      &:focus {
        outline: none;
      }

      &.message {
        height: 250px !important;

        @media (max-width: 768px) {
          height: 10vh !important;
        }
      }
    }
  }
`;

const Website = styled.input`
  display: none;
  `;

const SentMessage = styled.div`
  font-size: 20px;
  color: #000;
  font-weight: 800;
  transform: scaleX(0.9);
  margin-top: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactForm = () => {
  const blankFields = {
    name: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
    website: "",
  };

  const [userData, setUserData] = useState(blankFields);
  const [disableButton, setDisableButton] = useState(true);
  const [isSent, setIsSent] = useState(null);
  const form = useRef();

  const resetForm = () => {
    setUserData(blankFields);
  };

  useEffect(() => {
    if (
      userData.name !== "" &&
      userData.lastname !== "" &&
      userData.email !== "" &&
      userData.subject !== "" &&
      userData.message !== "" &&
      userData.website === ""
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [userData]);

  const sendEmail = () => {
    if (userData.website === "") {
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID, 
      process.env.REACT_APP_TEMPLATE_ID, 
      form.current, 
      process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then((result) => {
        setIsSent("ok");
      }, (error) => {
        setIsSent("error");
      });
    }
  };

  const sendContact = (event) => {
    event.preventDefault();
    setDisableButton(true);
    sendEmail();
    resetForm();
  };

  const changeData = (event) => {
    setUserData({
      ...userData,
      [event.target.id]: event.target.value,
    });
  };

  return (
    <ContactFormContainer>
      <form ref={form} onSubmit={sendContact} noValidate autoComplete="off">
        <input
          type="text"
          id="name"
          onChange={changeData}
          value={userData.name}
          required
          placeholder="NOMBRE"
          name='name'
        />
        <input
          type="text"
          id="lastname"
          onChange={changeData}
          value={userData.lastname}
          required
          placeholder="APELLIDOS"
          name='lastname'
        />

        <input
          type="email"
          id="email"
          onChange={changeData}
          value={userData.email}
          required
          placeholder="EMAIL"
          name='email'
        />

        <Website 
          type="text"
          id="website"
          placeholder="WEBSITE"
          name='website'
          onChange={changeData}
          value={userData.website}
        />

        <input
          type="text"
          id="subject"
          onChange={changeData}
          value={userData.subject}
          required
          placeholder="ASUNTO"
          name='subject'
        />

        <textarea
          type="text"
          className="message"
          id="message"
          onChange={changeData}
          value={userData.message}
          required
          placeholder="MENSAJE"
          name='message'
        />
        <button
          type="submit"
          className={disableButton ? "disabled" : ""}
          disabled={disableButton ? true : false}
        >
          ENVIAR
        </button>
       <SentMessage> {isSent === "ok" ? "enviado" : ""} {isSent === "error" ? "no enviado" : ""}</SentMessage>
      </form>

    </ContactFormContainer>
  );
};

export default ContactForm;
