import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: []
  },
  reducers: {
    loadProducts: (state, action) => {
      state.products = action.payload;
    }
  }
});

export const { loadProducts: loadProductsActionCreator } = productSlice.actions;

export default productSlice.reducer;