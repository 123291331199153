import { useSelector } from "react-redux";
import styled from "styled-components";
import Product from "./Product/Product";

const MerchandisingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 2;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 0;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    justify-content: flex-start;

    /* > :last-child {
      padding-bottom: 150px;
    } */
  }
`;

const Blank = styled.div`
  width: 100%;
  height: 185px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Merchandising = () => {
  const products = useSelector(state => state.products.products)

  return (
    <MerchandisingContainer className="merchContainer">
      {products.map((product) => <Product key={product.id} product={product}></Product>)}
      <Blank/>
    </MerchandisingContainer>
  )
}

export default Merchandising